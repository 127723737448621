<template>
  <UiInputVInput
    :model-value="modelValue"
    :append-inner-icon="type === 'text' ? eyeOff : eye"
    :type="type"
    :error="error"
    :placeholder="placeholder"
    :required="required"
    @click:inner-icon="onInnerIconClick"
    @input="onInput"
  />
</template>

<script setup lang="ts">
import { SvgoIconEye, SvgoIconEyeOff } from "#components";

defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  error: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "input"]);

const type = ref("password");

const eye = markRaw(SvgoIconEye);
const eyeOff = markRaw(SvgoIconEyeOff);

function onInnerIconClick() {
  type.value = type.value === "password" ? "text" : "password";
}

function onInput(value: any) {
  emit("update:modelValue", (value.target as HTMLInputElement).value);
  emit("input");
}
</script>
